import Component from './component';
import createjs from 'preload-js';

export const PRELOAD_EVENT = {
    'COMPLETE': 'preload_event__complete',
};
export default class Preload extends Component {
    constructor(component) {
        super(component);
        
        // METHOD
        this._handleAllComplete = this._handleAllComplete.bind(this);
    }

    /**
     * INIT
     */
    init() {
        super.init();
    }

    /**
     * LOAD IMAGE
     */
    loadAllImages() {
        const _images = document.querySelectorAll('img');
        let _manifest = [];
        let _queue = new createjs.LoadQueue(false);
        _queue.setMaxConnections(8);
        _queue.on('fileload', this._handleFileComplete);
        _queue.on('complete', this._handleAllComplete);

        for(let i = 0; i < _images.length; i++) {
            if(_images[i].dataset.src) {
                _manifest.push({
                    id: i,
                    src: _images[i].dataset.src,
                    target: _images[i]
                });
            }
        }

        _queue.loadManifest(_manifest);
    }

    /**
     * FILE COMPLETE
     * @param event
     * @private
     */
    _handleFileComplete(event) {
        event.item.target.setAttribute('src', event.item.src);
    }

    /**
     * ALL COMPLETE
     * @param event
     * @private
     */
    _handleAllComplete(event) {
        this.emit(PRELOAD_EVENT.COMPLETE);
    }
}