import Page from "../page";
import AnimationManager from "../../components/animationManager";
import device from 'current-device';
import lottie from "lottie-web";

export default class HomeIndex extends Page {
    constructor(component) {
        super(component);

        // DOM
        this.dom.lottieTitles = this._findAll('.push-item .push-title');

        // VAR
        this.lotties = [];
    }

    init() {
        super.init();

        // LOTTIES
        this._setLottieTitles();

        // ANIMATION
        this.animationManager = new AnimationManager();
        this.animationManager.init();

        // BIND
        this.bind();
    }

    bind() {
        super.bind();

        // READY
        this.ready();
    }

    /**
     * SHOW
     */
    show() {
        let _tl = new TimelineMax();
        if(!device.mobile())
            _tl.to('.background .desktop', 2, {scale: 1,  x:'-50%', z:0.01, ease:Power3.easeOut});
        else
            _tl.to('.background .mobile', 2, {scale: 1,  x:'-50%', z:0.01, ease:Power3.easeOut});
        _tl.staggerTo('.word', 1.5, {y: 0, alpha: 1, ease:Power4.easeOut}, 0.04, 0.6);

        for(let i = 0; i < this.lotties.length; i++) {
            this.lotties[i].play();
            TweenLite.to(this.dom.lottieTitles[i].parentNode.parentNode.querySelector(".background .inner"), 1, {
                scale: 1,
                delay: i / 10,
                ease: Power4.easeOut
            });
        }
    }

    /**
     * CLEAN
     */
    clean() {
        this.animationManager.kill();
    }

    /**
     * SET LOTTIE TITLE
     * @private
     */
    _setLottieTitles() {
        for(let i = 0; i < this.dom.lottieTitles.length; i++) {

            let __target = this.dom.lottieTitles[i];
            let __lottie = lottie.loadAnimation({
                container: __target,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                path: __target.dataset.lottie
            });

            this.lotties.push(__lottie);
        }
    }
}