import EventEmitter from 'eventemitter3';
import {TimelineMax, TweenLite, CSSPlugin, AttrPlugin, ScrollToPlugin} from "gsap/all";

const plugins = [CSSPlugin, AttrPlugin, ScrollToPlugin];

export default class Component extends EventEmitter{
    constructor(component) {
        super();

        this.dom = {
            component
        };
    }

    init() {
        console.log(this.constructor.name, ':: INIT');
    }

    bind() {
    }

    unbind() {
    }

    show() {
        console.log(this.constructor.name, ':: SHOW');
    }

    hide() {
        console.log(this.constructor.name, ':: HIDE');
    }

    clean() {
    }

    resize() {
    }

    scroll() {
    }

    update() {
    }

    _find(selector) {
        return this.dom.component.querySelector(selector);
    }

    _findAll(selector) {
        return Array.prototype.slice.call(this.dom.component.querySelectorAll(selector), 0);
    }
}