import Component from "../components/component";

export const PAGE_EVENT = {
    'READY': 'page_event__ready',
    'CLEAN': 'page_event__clean',
    'CHANGE': 'page_event__change'
};

export default class Page extends Component {
    constructor(component) {
        super(component);
    }

    /**
     * INIT
     */
    init() {
        super.init();
    }

    /**
     * BIND
     */
    bind() {
        super.bind();
    }

    /**
     * READY
     */
    ready() {
        this.emit(PAGE_EVENT.READY);
    }
}