import _ from 'lodash';
import "@babel/polyfill";
import barba from '@barba/core';
import {PAGE_EVENT} from "./pages/page";
import HomeIndex from "./pages/home";
import device from 'current-device';
import Header from "./components/header";
import Preload, {PRELOAD_EVENT} from "./components/preload";
import Disclaimer, {DISCLAIMER_EVENT} from "./components/disclaimer";
import TerritoryIndex from "./pages/territory";
import CommonIndex from "./pages/common";
import {TimelineMax, TweenLite} from "gsap/all"

export default class App {
    constructor() {

        // DOM
        this.dom = {
            body: document.querySelector('body'),
            wrapper: document.querySelector('.wrapper'),
            main: document.querySelector('#main'),
            header: document.querySelector('#header')
        };

        // VAR
        this.firstLoad = true;
        this.namespace = null;
        this.page = null;
        this.previousPage = null;

        // BIND
        this.update = this.update.bind(this);
        this.handlerPreloadComplete = this.handlerPreloadComplete.bind(this);
        this.handlerHideDisclaimer = this.handlerHideDisclaimer.bind(this);

        // INIT
        this.init();
    }

    /**
     * INIT
     */
    init() {

        // BROWSER
        window.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
        window.isIE = /*@cc_on!@*/false || !!document.documentMode;
        window.isEdge = !isIE && !!window.StyleMedia;
        window.isFirefox = typeof InstallTrigger !== 'undefined';

        if(window.isEdge || window.isIE)
            this.dom.body.classList.add('is-edge');

        // WINDOW
        //window.scrollTo(0, 0);
        window.addEventListener('resize', _.throttle(this.resize.bind(this), 100), {passive: true});
        window.addEventListener('scroll', _.throttle(this.scroll.bind(this), 0), {passive: true});

        // PRELOAD
        this.preload = new Preload();
        this.preload.addListener(PRELOAD_EVENT.COMPLETE, this.handlerPreloadComplete);
        this.preload.init();

        // BIND
        this.bind();
    }

    /**
     * BIND
     */
    bind() {
        
        console.log(barba);

        // BARBA
        barba.hooks.afterAppear(({current}) => {
            this.namespace = current.namespace;
            let _tl = new TimelineMax({
                onComplete: () => {
                    this.preload.loadAllImages();
                }
            });
            _tl.to('#transition .logo', 1, {y: '-50%', alpha:1, ease:Power4.easeOut});
        });

        barba.hooks.beforeLeave(({current}) => {
            this.previousPage = this.page;
            this.page = null;
        });

        barba.hooks.afterLeave(({current}) => {
            this.previousPage.clean();
        });

        /*barba.hooks.enter(({current, next}) => {
            this.namespace = next.namespace;
            this.build();
        });*/

        barba.init({
            debug: false,
            transitions: [
                {
                    appear() {},
                    leave: ({ current }) => this.leaveAnimation(),
                    enter: ({ next }) => this.enter(next),
                    afterEnter: ({ trigger }) => this.afterEnterAnimation(trigger)
                },
                {
                    name: 'panel-transition',
                    from: {
                        custom: ({ trigger }) => {
                            return trigger.classList && trigger.classList.contains('use-panel-transition') && !device.mobile();
                        },
                    },
                    appear() {},
                    leave: (data) => this.leavePanelAnimation(data),
                    enter: ({ next }) => this.enter(next),
                    afterEnter: ({ next }) => this.afterEnterPanelAnimation(),
                }
            ]
        });

        let links = document.querySelectorAll('a[href]');
        let cbk = function(e) {
            if(e.currentTarget.href === window.location.href) {
                e.preventDefault();
                e.stopPropagation();
            }
        };

        for(var i = 0; i < links.length; i++) {
            links[i].addEventListener('click', cbk);
        }
    }

    leaveAnimation() {
        return new Promise(resolve => {
            let _tl = new TimelineMax({
                onComplete: () => {

                    //if(device.mobile()) {
                        this.header.close();
                    //}

                    resolve();
                }
            });
            _tl.set('#transition', {display: 'block'});
            _tl.set('#transition .background', {y: '100%'});
            _tl.set('#transition .logo', {y: '90%', alpha: 0});
            _tl.to('#transition .background', 1, {y: '0%', ease:Power4.easeOut}, 0.2);
            _tl.to('#transition .logo', 1, {y: '-50%', alpha:1, ease:Power4.easeOut}, 0.2);
        });
    }

    leavePanelAnimation(data) {
        
        return new Promise(resolve => {

            // DISABLED MENU
            let _cover = document.querySelector('#menu .covers .open');
            let _index = _cover.dataset.index;
            _cover.classList.add('current');
            _cover.style.zIndex = 20;

            document.querySelector('#menu').classList.add('disabled');

            let _outTl = new TimelineMax({
                onStart: () => {
                    document.querySelector('.wrapper').style.height = '100vh';
                },
                onComplete: () => {
                    resolve();
                }
            });
            _outTl.to(data.current.container, 0.6, {alpha: 0, ease:Power4.easeOut});
            _outTl.staggerTo('#menu .menu-item .title', 0.6, {
                alpha: 0,
                y: -20,
                ease:Power3.easeInOut,
                stagger: {
                    each: 0.08,
                    from: _index,
                }
            }, 0);

        });
    }

    afterEnterAnimation(trigger) {
        
        console.log('AFTER ENTER');

        if(trigger !== 'barba') {
            if(!trigger.classList.contains('no-scroll-refresh'))
                window.scrollTo(0, 0);
        }

        return new Promise(resolve => {

            resolve();

            let _tl = new TimelineMax({
                onStart: () => {
                    TweenLite.delayedCall(0.8, () => {
                        this.pageIsReady();
                    });
                },
                onComplete: () => {
                    TweenLite.set('#transition', {display: 'none'});
                }
            });
            _tl.to('#transition .logo', 1, {y: '-110%', alpha:0, ease:Power4.easeIn});
            _tl.to('#transition .background', 1, {y: '-100%', ease:Power4.easeInOut}, 0.5);
        });
    }

    afterEnterPanelAnimation() {
        window.scrollTo(0, 0);

        return new Promise(resolve => {

            let _cover = document.querySelector('#menu .covers .current');
            _cover.classList.add('open-now');

            TweenLite.delayedCall(0.6, () => {
                this.pageIsReady();

                //HEADER
                this.header.close();

                // GRID
                document.querySelector('.wrapper').style.height = 'auto';
                document.querySelector('#menu').classList.remove('disabled');

                // DONE
                resolve();
            });

        });
    }

    enter(next) {
        
        console.log('ENTER');

        this.namespace = next.namespace;
        this.build(next.container);
        return new Promise((resolve) => setTimeout(resolve,100));
    }

    /**
     * BUILD
     */
    build(i__container) {

        // FIRST LOAD
        if(this.firstLoad) {

            // DISCLAIMER
            this.disclaimer = new Disclaimer(document.querySelector('#disclaimer'));
            this.disclaimer.once(DISCLAIMER_EVENT.HIDE, this.handlerHideDisclaimer);
            this.disclaimer.init();

            // HEADER
            this.header = new Header(document.querySelector('#header'));
            this.header.init();
        }

        // PAGE
        switch (this.namespace) {
            case 'home':
                this.page = new HomeIndex(i__container);
            break;
            case 'territory':
                this.page = new TerritoryIndex(i__container);
            break;
            default:
                this.page = new CommonIndex(i__container);
                break;
        }

        if (this.page) {
            this.page.once(PAGE_EVENT.CHANGE, (event) => {
                this.page.removeListener(PAGE_EVENT.CHANGE);
                barba.go(event);
            });
            this.page.init();
            if(this.firstLoad)
                this.pageIsReady();
        }
    }

    /**
     * PAGE IS READY
     * @param event
     */
    pageIsReady() {

        if(this.firstLoad) {
            this.firstLoad = false;

            this.dom.wrapper.style.opacity = 1;
            this.dom.wrapper.style.visibility = 'visible';

            let _tl = new TimelineMax({
                onStart: () => {
                    TweenLite.delayedCall(0.5, () => {
                        // IF DISCLAIMER IS NOT SHOWED
                        if(!this.disclaimer.isShow) {
                            this.page.show();
                        }
                    });
                },
                onComplete: () => {
                    this.update();
                    this.resize();
                    this.scroll();
                    TweenLite.set('#transition', {display: 'none'});
                }
            });
            _tl.to('#transition .logo', 1, {y: '-110%', alpha:0, ease:Power4.easeIn});
            _tl.to('#transition .background', 1, {y: '-100%', ease:Power4.easeInOut}, 0.5);
        } else {
            this.resize();
            this.scroll();
            this.page.show();
        }
    }

    /**
     * SCROLL
     */
    scroll() {
        const _wScrollY = window.scrollY;

        // HEADER
        if(_wScrollY > 0 && !this.dom.header.classList.contains('out'))
            this.dom.header.classList.add('out');
        else if(_wScrollY === 0 && this.dom.header.classList.contains('out'))
            this.dom.header.classList.remove('out');

        // PAGE
        if(this.page)
            this.page.scroll();
    }

    /**
     * UPDATE
     */
    update() {
        window.requestAnimationFrame(this.update);

        // HEADER
        if(this.header)
            this.header.update();

        // PAGE
        if(this.page)
            this.page.update();
    }

    /**
     * RESIZE
     */
    resize() {

        if(this.header)
            this.header.resize();

        // PAGE
        if(this.page)
            this.page.resize();

        // HACK MOBILE HOME
/*        if(device.mobile() && this.dom.body.classList.contains('home'))
            document.querySelector('#home').style.height = (window.innerHeight - 110) + 'px';*/
    }

    /* ------------------------ HANDLERS ------------------------ */

    /**
     * HANDLER PRELOAD COMPLETE
     */
    handlerPreloadComplete() {
        this.build(document.querySelector('.page-tpl'));
    }

    /**
     * HANDLER DISCLAIMER HIDE
     */
    handlerHideDisclaimer() {
        this.page.show();
    }
}

const __dcl = () => {
    new App();
};

document.addEventListener('DOMContentLoaded', __dcl);