import Component from './component';
import lottie from 'lottie-web';
import device from 'current-device';
import SplitText from '../vendor/gsap/SplitText';

export default class AnimationManager extends Component {
    constructor(component) {
        super(component);

        // VAR
        this.isEnabled = true;
        this.frame = null;
        this.scrollTop = 0;
        this.triggerHook = 0;
        this.tweened = 0;
        this.lottieTitle = [];
        this.parallaxImages = [];
        this.animateElements = null;
        this.observer = null;
    }

    /**
     * INIT
     */
    init() {
        super.init();

        // SET
        if(!device.mobile())
            this.setParallax();

        this.setLottieTitle();
        this.setSplitText();
        this.setObserver();
    }

    /**
     * START
     */
    start() {
        this.enabledObserver();
    }

    /**
     * STOP
     */
    kill() {
        this.disabledObserver();
    }

    /**
     * SCROLL
     */
    scroll() {
        if(this.isEnabled) {
            this.triggerHook = window.innerHeight;
            this.scrollTop = Math.max(0, document.documentElement.scrollTop || window.pageYOffset || 0);
        }
    }

    /**
     * UPDATE
     */
    update() {
        if(this.isEnabled) {
            
            // PARALLAX
            for(let i = 0; i < this.parallaxImages.length; i++) {
                let __target = this.parallaxImages[i];

                const __targetHeightDistance = __target.height + this.triggerHook;
                const __targetTop = __target.element.getBoundingClientRect().top - this.triggerHook;
                const __percentageY = __targetTop / __targetHeightDistance;
                const __percentageDelta = __target.deltaY * __percentageY;
                const __top = __target.tweened += .05 * (__percentageDelta - __target.tweened);

                __target.image.style.transform = `translateX(-50%) translateY(${(__top * 1)}px) translateZ(0) scale(1)`;
            }
        }
    }

    /**
     * ANIMATE
     */
    animate(i__target) {
        const _target = i__target;
        _target.classList.add('is-animated');
        let _delay = 0.2;

        if(_target.dataset.adelay && !device.mobile())
            _delay = _delay + parseFloat(_target.dataset.adelay);

        let _tl = new TimelineMax({
            delay: _delay
        });

        // SCALE
        if(_target.classList.contains('a-scale')) {
            _tl.to(_target, 2, {x: '-50%', scale: 1.03, ease:Power3.easeInOut}, -0.2);
        }

        // SPLIT
        if(_target.classList.contains('a-split')) {
            _tl.staggerTo(_target.querySelectorAll('.word'), 1, {y: '-12%', alpha: 1, ease:Power4.easeOut}, 0.04, 0);
        }

        // FADE
        if(_target.classList.contains('a-fade')) {
            _tl.to(_target, 2, {y: 0, alpha: 1, ease:Power4.easeOut}, 0);
        }

        // LOTTIE
        if(_target.classList.contains('a-lottie')) {
            this.lottieTitle[parseInt(_target.dataset.lottieIndex, 10)].animation.play();
        }

        // LINE
        if(_target.classList.contains('a-lines')) {
            _tl.to(_target, 1, {y:0, alpha: 1, ease:Power4.easeOut}, 0);
            _tl.staggerTo(_target.querySelectorAll('.line'), 1, {scale: 1, ease:Power4.easeOut}, 0.02, 0.2);
        }

        // SCROLL
        if(_target.classList.contains('a-scroll')) {
            _tl.to(_target, 1, {alpha: 1, ease:Power4.easeOut,
                onComplete: () => {
                    _target.querySelector('.line').classList.add('out')
                }
            }, 0);
        }
    }

    /**
     * SET LOTTIE TITLE
     */
    setLottieTitle() {
        const _elements = document.querySelectorAll('.lottie-title');

        for(let i = 0; i < _elements.length; i++) {
            let __target = _elements[i];
            __target.dataset.lottieIndex = i;
            let __file = __target.dataset.lottie;
            let __lottie = lottie.loadAnimation({
                container: __target,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                path: __file
            });

            this.lottieTitle.push({
                element: __target,
                animation: __lottie
            });
        }
    }

    /**
     * PARALLAX
     */
    setParallax() {
        const _elements = document.querySelectorAll('.a-parallax');
        for(let i = 0; i < _elements.length; i++) {
            let __target = _elements[i];
            let __image = __target.querySelector('img');
            __image.style.transform = 'translateX(-50%) translateY(0) translateZ(0) scale(1) ';

            this.parallaxImages.push({
                element: __target,
                image: __image,
                height: __target.offsetHeight,
                imageHeight: __image.getAttribute('height'),
                deltaY: Math.max(0, __image.getAttribute('height') - __target.offsetHeight),
                tweened: 0
            });
        }
    }

    /**
     * SPLIT TEXT
     */
    setSplitText() {
        const _elements = document.querySelectorAll('.a-split');
        for(let i = 0; i < _elements.length; i++) {
            let __split = new SplitText(_elements[i], {type:'words', wordsClass:'word word++'});
        }
    }

    /**
     * SET OBSERVER
     */
    setObserver() {
        this.animateElements = document.querySelectorAll('.a-animate');
        const config = {
            threshold: [0.25]
        };
        this.observer = new IntersectionObserver(entries => {
            console.log('IN OBSER');
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    this.animate(entry.target);
                    this.observer.unobserve(entry.target);
                }
            });
        }, config);
    }

    /**
     * ENABLED OBSERVER
     */
    enabledObserver() {
        this.animateElements.forEach(image => {
            this.observer.observe(image);
        });
    }

    /**
     * DISABLED OBSERVER
     */
    disabledObserver() {
        this.animateElements.forEach(image => {
            this.observer.unobserve(image);
        });
    }
}