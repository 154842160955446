import Component from './component';
import lottie from 'lottie-web';
import device from 'current-device';
import * as PIXI from 'pixi.js';
import PixiPlugin from '../vendor/gsap/PixiPlugin';

export default class Header extends Component {
    constructor(component) {
        super(component);

        // DOM
        this.dom.open = this._find('.open-menu');
        this.dom.menu = document.querySelector('#menu');
        this.dom.close = document.querySelector('#menu .close-menu');
        this.dom.lottieTitles = document.querySelectorAll('#menu .title');
        this.dom.menuItems = document.querySelectorAll('#menu .menu-item');
        this.dom.menuCovers = document.querySelectorAll('#menu .covers .cover');
        this.dom.background = document.querySelector('#menu .background');
        this.dom.canvas = document.querySelector('#menu #canvas-menu');

        // VAR
        this.lotties = [];
        this.videoRealRatio = 2079 / 978;

        // BIND
        this._open = this._open.bind(this);
        this.close = this.close.bind(this);
    }

    /**
     * INIT
     */
    init() {
        super.init();

        // PIXI
        this._createCanvas();

        // LOTTIE
        this._setLottieTitles();

        // BIND
        this.bind();
    }

    /**
     * BIND
     */
    bind() {

        // MENU
        this.dom.open.addEventListener('click', this._open);
        this.dom.close.addEventListener('click', this.close);

        // MENU ITEM
        if(!device.mobile()) {
            for(let i = 0; i < this.dom.menuItems.length; i++) {
                let __item = this.dom.menuItems[i];
                let __cover = this.dom.menuCovers[i];
                let __polygonClose = null;
                let __polygonOpen = null;

                switch (i) {
                    case 0:
                        __polygonClose = "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)";
                        __polygonOpen =  "polygon(0% 0%, 25% 0%, 25% 100%, 0% 100%)";
                        break;
                    case 1:
                        __polygonClose = "polygon(25% 0%, 25% 0%, 25% 100%, 25% 100%)";
                        __polygonOpen =  "polygon(25% 0%, 50% 0%, 50% 100%, 25% 100%)";
                        break;
                    case 2:
                        __polygonClose = "polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%)";
                        __polygonOpen =  "polygon(50% 0%, 75% 0%, 75% 100%, 50% 100%)";
                        break;
                    case 3:
                        __polygonClose = "polygon(75% 0%, 75% 0%, 75% 100%, 75% 100%)";
                        __polygonOpen =  "polygon(75% 0%, 100% 0%, 100% 100%, 75% 100%)";
                        break;
                }

                __item.addEventListener('mouseenter', () => {
                    __cover.classList.add('open');
                    /*TweenLite.to(__cover, 0.6, {
                        webkitClipPath: __polygonOpen,
                        clipPath: __polygonOpen,
                        ease: Power3.easeInOut
                    });*/
                });
                __item.addEventListener('mouseleave', () => {
                    __cover.classList.remove('open');
                    /*if(!__cover.classList.contains('current')) {
                        TweenLite.to(__cover, 0.6, {
                            webkitClipPath: __polygonClose,
                            clipPath: __polygonClose,
                            ease: Power3.easeInOut
                        });
                    }*/
                });
            }
        }
    }

    /**
     * SCROLL
     */
    scroll() {
        super.scroll();
    }

    /**
     * RESIZE
     */
    resize() {
        super.resize();
        
        console.log('RESIZE');

        // CONST
        const windowWidth = document.body.clientWidth;
        const windowHeight = window.innerHeight;

        // RENDER
        this.app.renderer.resize(windowWidth - 80, windowHeight - 140);
        const screenRatio = this.app.screen.width / this.app.screen.height;
        let videoRatio = this.app.screen.width / 2079;
        if(screenRatio < this.videoRealRatio)
            videoRatio = this.app.screen.height / 978;

        if(videoRatio < 1) {
            videoRatio = 1;
        }

        // CONTAINER
        if(this.container) {
            this.container.x = this.app.screen.width / 2;
            this.container.y = this.app.screen.height;
            this.container.scale.x = this.container.scale.y = videoRatio;

            this.containerMasks.x = 0;
            this.containerMasks.y = 0;

            const __screenWidth = Math.ceil(this.app.screen.width);

            for(let i = 0; i < this.masks.length; i++) {
                let __mask = this.masks[i];
                let __xPos = i * (__screenWidth / 4);
                let __width = Math.ceil((__screenWidth / 4) + 10);

                __mask.clear();
                __mask.beginFill(0xFFFF00);
                __mask.drawRect(0, 0, __width, this.app.screen.height);
                __mask.x = __xPos;
                __mask.y = 0;
                __mask.lineStyle(0);
            }
        }

    }

    /**
     * UPDATE
     */
    update() {
        this.ticker.update();
    }

    /**
     * CLEAN
     */
    clean() {
        for(let i = 0; i < this.lotties.length; i++) {
            this.lotties[i].goToAndStop(0);
        }
    }

    _createCanvas() {

        const windowWidth = document.body.clientWidth;
        const windowHeight = window.innerHeight;

        // APP
        this.app = new PIXI.Application({
            autoResize: true,
            resolution: devicePixelRatio,
            transparent: true,
            width: windowWidth - 80,
            height: windowHeight - 140
        });
        this.dom.canvas.appendChild(this.app.view);

        // TICKER
        this.ticker = PIXI.ticker.shared;
        this.ticker.autoStart = false;
        this.ticker.stop();

        // CONTAINER
        this.container = new PIXI.Container();
        this.app.stage.addChild(this.container);

        this.containerMasks = new PIXI.Container();
        this.app.stage.addChild(this.containerMasks);

        // SPRITE
        const _texture = PIXI.Texture.fromImage(THEME_URL + '/assets/images/home/background-black.jpg');



        // MASK
        this.sprites = [];
        this.masks = [];
        const _rendererWidth = this.app.screen.width;
        const _rendererHeight = this.app.screen.height;

        for(let i = 0; i < 4; i++) {

            // VAR
            let __xPos = i * (_rendererWidth / 4);
            let __width = Math.ceil((_rendererWidth / 4));

            // SPRITE
            let __sprite = new PIXI.Sprite(_texture);
            __sprite.anchor.x = 0.5;
            __sprite.anchor.y = 1;
            this.container.addChild(__sprite);
            this.sprites.push(__sprite);

            // MASK
            let __mask = new PIXI.Graphics();
            __mask.beginFill(0xFFFF00);
            __mask.drawRect(0, 0, __width, _rendererHeight);
            __mask.x = __xPos;
            __mask.y = 0;
            __mask.lineStyle(0);
            this.containerMasks.addChild(__mask);
            this.masks.push(__mask);

            __sprite.mask = __mask;
            TweenLite.set(__mask, {pixi: {scaleX: 0}});
        }

/*        TweenLite.set(this.mask[0], {pixi: {scaleX: 0}});
        TweenLite.to(this.mask[0], 1, {pixi: {scaleX: 1}, ease:Power4.easeInOut, delay: 2});*/

/*        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext("2d");
        ctx.rect(20, 20, 150, 100);
        ctx.fillStyle = "green";
        ctx.fill();


        let canvastexture = PIXI.Texture.fromCanvas(canvas);
        let spriteMask = new PIXI.Sprite(canvastexture);*/
        //this.spriteBackgroundImage.mask = spriteMask;

/*        this.spriteBackgroundImage.mask = this.mask[0];

        TweenLite.set(this.mask[0], {pixi: {scaleX: 0}});
        TweenLite.to(this.mask[0], 1, {pixi: {scaleX: 1}, ease:Power4.easeInOut, delay: 2});*/

/*        var renderTexture = PIXI.RenderTexture.create(this.app.screen.width, this.app.screen.height);

        var renderTextureSprite = new PIXI.Sprite(renderTexture);
        this.app.stage.addChild(renderTextureSprite);
        this.spriteBackgroundImage.mask = renderTextureSprite;*/


/*        this.container.mask = this.mask[0];
        this.container.mask = this.mask[1];*/

        //this.spriteBackgroundImage.masks.add(this.mask[0]);

        //this.container.mash.push(this, this.mask[1]);

/*        console.log(this.mask[0]);
        this.spriteBackgroundImage.mask = this.mask[0];*/

        //TweenLite.staggerTo(this.mask)
    }

    /**
     * SET LOTTIE TITLE
     * @private
     */
    _setLottieTitles() {
        for(let i = 0; i < this.dom.lottieTitles.length; i++) {

            let __target = this.dom.lottieTitles[i];
            let __lottie = lottie.loadAnimation({
                container: __target,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                path: __target.dataset.lottie
            });

            this.lotties.push(__lottie);
        }
    }

    /**
     * OPEN
     * @private
     */
    _open() {
        if(window.scrollY > 0) {
            TweenLite.to(window, 0.8, {scrollTo: {y:0}, ease:Power4.easeOut,
                onComplete: () => {
                    this._doOpenAnimation();
                }
            });
        } else {
            this._doOpenAnimation();
        }
    }

    _doOpenAnimation() {
        if(!device.mobile()) {
            let _openTl = new TimelineMax({
                onStart: () => {
                    TweenLite.delayedCall(0.5, () => {
                        for(let i = 0; i < this.lotties.length; i++) {
                            this.lotties[i].play();
                        }
                    })
                },
                onUpdate: () => {
                    if(window.isSafari)
                        this._redraw();
                }
            });
            _openTl.set('#menu .background img.desktop', {scale:1.1, x:'-50%'}, 0);
            _openTl.to('#menu', 0.8, {autoAlpha:1, ease:Power4.easeOut}, 0);
            _openTl.from(this.sprites, 2.5, {pixi:{scale:1.1}, ease:Power3.easeOut}, 0);
            _openTl.staggerTo(this.masks, 1.3, {pixi:{scaleX:1}, ease:Power3.easeInOut}, 0.05, 0);
            _openTl.from('#menu .langs', 0.6, {alpha: 0, y: 10, ease:Power3.easeOut}, 1);
            //_openTl.to('#menu .background img.desktop', 2.5, {scale:1, x:'-50%', z:0.01, ease:Power3.easeOut}, 0);
            //_openTl.staggerTo('#clipPath1 rect', 1.3, {scaleX:1, ease:Power3.easeInOut}, 0.05, 0);
        } else {
            let _openTl = new TimelineMax({
                onStart: () => {
                    TweenLite.delayedCall(0.5, () => {
                        for(let i = 0; i < this.lotties.length; i++) {
                            this.lotties[i].play();
                        }
                    })
                }
            });
            _openTl.set('#menu .background img.mobile', {scale:1.1, x:'-50%'}, 0);
            _openTl.to('#menu', 0.8, {autoAlpha:1, ease:Power4.easeOut}, 0);
            _openTl.to('#menu .background img.mobile', 2.5, {scale:1, x:'-50%', z:0.01, ease:Power3.easeOut}, 0);
        }
    }

    /**
     * CLOSE
     */
    close() {
        let _closeTl = new TimelineMax({
            onComplete: () => {
                for(let i = 0; i < this.lotties.length; i++) {
                    this.lotties[i].goToAndStop(0);
                }

                // COVER
                if(!device.mobile()) {
                    let _cover = document.querySelector('#menu .covers .current');
                    if(_cover) {
                        _cover.style.zIndex = 2;
                        _cover.classList.remove('current', 'open-now');
                    }

                    TweenLite.set(this.masks, {pixi:{scaleX:0}}, 0);
                    TweenLite.set('#menu .menu-item .title', {y:0, alpha: 1});
                }

            }
        });
        _closeTl.to('#menu', 0.6, {autoAlpha:0, ease:Power3.easeOut});
    }

    /**
     * REDRAW
     * @private
     */
    _redraw() {
        this.dom.background.style.clipPath = "none";
        this.dom.background.offsetWidth; //force a style recalculation
        this.dom.background.style.clipPath = "url(#path-menu)";
    };
}