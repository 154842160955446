import Page, {PAGE_EVENT} from "../page";
import AnimationManager from "../../components/animationManager";
import Form, {FORM_EVENT} from "../../components/form";
import Glide from "@glidejs/glide";
import mediabox from 'mediabox';

export default class CommonIndex extends Page {
    constructor(component) {
        super(component);

        // DOM
        this.dom.glideSlider = this._find('.glide');
        this.dom.shareButtons = this._findAll('.share-button');
        this.dom.tabLinks = this._find('#tab-links');
        this.dom.form = this._find('form');

        // VAR
        this.glide = null;
        this.video = null;

        // FUNCTION
        this._shareEvent = this._shareEvent.bind(this);
        this._changeTabUrl = this._changeTabUrl.bind(this);
    }

    init() {
        super.init();

        // ANIMATION
        this.animationManager = new AnimationManager();
        this.animationManager.init();

        // VIDEO
        this.video = document.querySelector('.page-cover .background video');

        // GLIDE
        if(this.dom.glideSlider) {
            console.log('GLIDDE', this.dom.glideSlider);
            this.glide = new Glide('.glide', {
                type: 'carousel',
                startAt: 0,
                perView: 1,
                gap: 30,
                autoplay: 3000,
                hoverpause: false,
                animationTimingFunc: 'cubic-bezier(0.455, 0.030, 0.515, 0.955)',
                animationDuration: 1000
            }).mount();
        }

        // FORM
        if(this.dom.form) {
            this.form = new Form(this.dom.form);
            this.form.addListener(FORM_EVENT.SUCCESS, () => {
               this.form.reset();
            });
            this.form.init();
        }

        // BIND
        this.bind();
    }

    /**
     * BIND
     */
    bind() {
        super.bind();

        // SHARE
        for(let i = 0; i < this.dom.shareButtons.length; i++) {
            this.dom.shareButtons[i].addEventListener('click', this._shareEvent);
        }

        // TAB
        if(this.dom.tabLinks)
            this.dom.tabLinks.addEventListener('change', this._changeTabUrl);

        // MEDIA BOX
        mediabox('.mediabox' , { rel: '0' });
    }

    /**
     * UNBIND
     */
    unbind() {
        super.unbind();

        // SHARE
        for(let i = 0; i < this.dom.shareButtons.length; i++) {
            this.dom.shareButtons[i].removeEventListener('click', this._shareEvent);
        }

        // TAB
        if(this.dom.tabLinks)
            this.dom.tabLinks.removeEventListener('change', this._changeTabUrl);
    }

    /**
     * SHOW
     */
    show() {
        if(this.animationManager)
            this.animationManager.start();

        if(this.video) {
            this.video.play();
        }
    }

    /**
     * SCROLL
     */
    scroll() {
        if(this.animationManager)
            this.animationManager.scroll();
    }

    /**
     * UPDATE
     */
    update() {
        if(this.animationManager)
            this.animationManager.update();
    }

    /**
     * CLEAN
     */
    clean() {
        this.animationManager.kill();
        this.unbind();

        if(this.glide)
            this.glide.destroy();
    }

    /**
     * CHANGE URL
     * @param event
     * @private
     */
    _changeTabUrl(event) {
        this.emit(PAGE_EVENT.CHANGE, event.target.value);
    }

    /**
     * SHARE EVENT
     * @param event
     * @private
     */
    _shareEvent(event) {
        const _target = event.currentTarget;
        const _url = _target.dataset.url;
        const _title = this._stripHtml( _target.dataset.title);
        const _image = _target.dataset.image;
        const _description = _target.dataset.description;
        
        console.log(_description);

        let width  = 575,
            height = 400,
            left   = window.innerWidth  / 2,
            top    = window.innerHeight / 2;

        if(_target.classList.contains('facebook')) {
            FB.ui({
                method: 'share',
                action_type: 'og.shares',
                display: 'popup',
                action_properties: JSON.stringify({
                    object: {
                        'og:url': _url,
                        'og:title': _title,
                        'og:image': _image,
                        'og:description': _description,
                    }
                })
            }, function(response){console.log(response);});
        }
        else if(_target.classList.contains('twitter') ) {
            let url    = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(_url) +'&text=' + _title,
                opts   = 'status=1' + ',width='  + width  + ',height=' + height + ',top='    + top    + ',left='   + left;
            window.open(url, 'twitter', opts);
        }
        else if(_target.classList.contains('linkedin')) {
            let url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + _url + '&title='  + _title + '&source=' + _url,
                opts   = 'status=1' + ',width='  + width  + ',height=' + height + ',top='    + top    + ',left='   + left;
            window.open(url, 'linkedin', opts)
        }
    }

    /**
     * STRIP HTML
     * @param html
     * @returns {string | string}
     * @private
     */
    _stripHtml(html){
        let temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }
}