import Page from "../page";
import AnimationManager from "../../components/animationManager";

export default class TerritoryIndex extends Page {
    constructor(component) {
        super(component);

        // DOM
        this.dom.wineTitles = this._findAll('.wine-title');
        this.dom.bulletItems = this._findAll('.bullet-item');
        this.dom.overlay = document.querySelector('#overlay');
        this.dom.overlayTitle = document.querySelector('#overlay .title');
        this.dom.overlayDescription = document.querySelector('#overlay .description');
        this.dom.overlayClose = document.querySelector('#overlay .close');

        // VAR
        this.previousWineDescription = null;
        this.currentWineDescription = this._find('.wine-description.d-1');
        this.isProcessing = false;

        // FUNCTION
        this._selectCurrentWineDescription = this._selectCurrentWineDescription.bind(this);
        this._showCurrentBulletContent = this._showCurrentBulletContent.bind(this);
        this._hideOverlay = this._hideOverlay.bind(this);
    }

    init() {
        super.init();

        // ANIMATION
        this.animationManager = new AnimationManager();
        this.animationManager.init();

        // BIND
        this.bind();
    }

    bind() {
        super.bind();

        // WINE TITLE
        for(let i = 0; i < this.dom.wineTitles.length; i++) {
            let __target = this.dom.wineTitles[i].addEventListener('click', this._selectCurrentWineDescription);
        }

        // BULLETS
        for(let i = 0; i < this.dom.bulletItems.length; i++) {
            let __target = this.dom.bulletItems[i].addEventListener('click', this._showCurrentBulletContent);
        }

        // OVERLAY
        this.dom.overlayClose.addEventListener('click', this._hideOverlay);

        // READY
        this.ready();
    }

    /**
     * UNBIND
     */
    unbind() {
        // WIN TITLE
        for(let i = 0; i < this.dom.wineTitles.length; i++) {
            this.dom.wineTitles[i].removeEventListener('click', this._selectCurrentWineDescription);
        }

        // BULLETS
        for(let i = 0; i < this.dom.bulletItems.length; i++) {
            this.dom.bulletItems[i].removeEventListener('click', this._showCurrentBulletContent);
        }

        // OVERLAY
        this.dom.overlayClose.removeEventListener('click', this._hideOverlay);
    }

    /**
     * SHOW
     */
    show() {
        if(this.animationManager)
            this.animationManager.start();
    }

    /**
     * SCROLL
     */
    scroll() {
        if(this.animationManager)
            this.animationManager.scroll();
    }

    /**
     * UPDATE
     */
    update() {
        if(this.animationManager)
            this.animationManager.update();
    }

    /**
     * CLEAN
     */
    clean() {
        this.animationManager.kill();
        this.unbind();
    }

    /**
     * SELECT CURRENT WINE DESCRIPTION
     * @private
     */
    _selectCurrentWineDescription(event) {

        if(!this.isProcessing) {
            this.isProcessing = true;
            let _index = event.currentTarget.dataset.index;
            this.previousWineDescription = this.currentWineDescription;
            this.currentWineDescription = this._find('.wine-description.d-' + _index);

            this._find('.wine-title.current').classList.remove('current');
            event.currentTarget.classList.add('current');

            let _tl = new TimelineMax({
                onComplete: () => {
                    this.isProcessing = false;
                    this.previousWineDescription = null;
                }
            });
            _tl.set(this.currentWineDescription.querySelectorAll('.word'), {y: 20, alpha: 0}, 0);
            if(this.previousWineDescription) {
                console.log(this.previousWineDescription.querySelectorAll('.word'));
                _tl.staggerTo(this.previousWineDescription.querySelectorAll('.word'), 0.4, {y: -20, alpha: 0, ease:Power4.easeIn}, 0.02);
            }
            _tl.addLabel('previousOut');
            _tl.staggerTo(this.currentWineDescription.querySelectorAll('.word'), 0.4, {y: 0, alpha: 1, ease:Power4.easeOut}, 0.02, 'previousOut-=0.2');
        }
    }

    /**
     * SHOW CURRENT BULLET CONTNET
     * @private
     */
    _showCurrentBulletContent(event) {
        let _title = event.currentTarget.dataset.title;
        let _content = event.currentTarget.dataset.content;

        this.dom.overlayTitle.innerHTML = _title;
        this.dom.overlayDescription.innerHTML = _content;

        let _tl = new TimelineMax();
        _tl.to('#overlay', 0.8, {autoAlpha: 1, ease:Power4.easeOut});
    }

    /**
     * HIDE OVERLAY
     * @private
     */
    _hideOverlay() {
        TweenLite.to('#overlay', 0.3, {autoAlpha: 0, ease:Power4.easeOut});
    }
}