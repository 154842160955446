import Component from './component';

export const DISCLAIMER_EVENT = {
    'HIDE': 'disclaimer_event__hide',
};

export default class Disclaimer extends Component {
    constructor(component) {
        super(component);

        // DOM
        this.dom.buttonYes = this._find('.button-rect.valid');
        this.dom.buttonNo = this._find('.button-rect.refuse');
        this.dom.closeRGPD = document.querySelector('#rgpd .close');

        // VAR
        this.isShow = false;

        // METHOD
        this._validDisclaimer = this._validDisclaimer.bind(this);
        this._refuseDisclaimer = this._refuseDisclaimer.bind(this);
    }

    /**
     * INIT
     */
    init() {
        super.init();
        
        // GET
        const localStorageValue = localStorage.getItem('valid_disclaimer_age');
        if(!localStorageValue) {
            this.dom.component.style.display = 'block';
            this.isShow = true;
            this.bind();
        } else {
            this.dom.component.parentNode.removeChild(this.dom.component);
        }

        const _validRGPD = localStorage.getItem('valid_rgpd');
        if(!_validRGPD) {
            document.querySelector('#rgpd').style.display = 'block';
            this.dom.closeRGPD.addEventListener('click', this._hideRGPD.bind(this));
        }
    }

    /**
     * BIND
     */
    bind() {
        this.dom.buttonYes.addEventListener('click', this._validDisclaimer);
        this.dom.buttonNo.addEventListener('click', this._refuseDisclaimer);
    }

    /**
     * HIDE
     */
    hide() {
        let _tl = new TimelineMax({
            onStart: () => {
                TweenLite.delayedCall(0.8, () => {
                    this.emit(DISCLAIMER_EVENT.HIDE);
                });
            },
            onComplete: () => {
                this.isShow =  false;
                this.unbind();
                this.dom.component.parentNode.removeChild(this.dom.component);
            }
        });
        _tl.staggerTo('#disclaimer .a-stagger', 0.6, {
            y: 20,
            alpha: 0,
            ease:Power4.easeIn,
            stagger: {
                grid: 'auto',
                from: 'end',
                each: 0.2
            }
        });
        _tl.to('#disclaimer', 1, {alpha: 0, ease:Power4.easeOut}, 0.8);
    }

    /**
     * UNBIND
     */
    unbind() {
        this.dom.buttonYes.removeEventListener('click', this._validDisclaimer);
        this.dom.buttonNo.removeEventListener('click', this._refuseDisclaimer);
    }

    /**
     * VALID DISCLAIMER
     * @private
     */
    _validDisclaimer() {
        //localStorage.setItem('valid_disclaimer_age', true);
        this.hide();
    }

    /**
     * REFUSE DISCLAIMER
     * @private
     */
    _refuseDisclaimer() {
        window.location.href = "http://www.google.fr";
    }


    _hideRGPD() {
        localStorage.setItem('valid_rgpd', true);
        TweenLite.to('#rgpd', 0.6, {autoAlpha: 0, ease:Power4.easeOut});
    }
}